.shop {
  background-color: #EFEFF4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.shop__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 163px);
  gap: 10px;
  justify-content: center;
  padding:10px;
  overflow-y: auto;
  height: calc(100vh - 70px);
}
.shop__list.empty {
  height: auto;
}
.shop__bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.shop__bottom .shopButton {
  border-radius: 8px;
}
