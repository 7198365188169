.cart {
  overflow-y: auto;
}

.cart_comment {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #011627;
  border: none;
  margin-top: 16px;
}
.cart_comment label {
  text-align: left;
  margin-bottom: 8px;
}
.cart_comment textarea {
  outline: none;
  resize: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;

  padding: 12px 16px;
  background: #F6F6F6;
  border: 1px solid #E0E0E0;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  color: #000000;
}
.cart_comment textarea::placeholder {
  color: #BDBDBD;
}

.cart__button {
  width: 100%;
  height: 48px;
  border-radius: 0 0 24px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-bottom: 3px;
}
