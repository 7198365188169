.slider {
  display: flex;
  position: relative;
  justify-content: center;
}

.slider__slide {
  height: 240px;
}

.slider__slide img {
  width: 320px;
  height: inherit;
  object-fit: cover;
  pointer-events: none;
}

.react-slideshow-container {
  display: flex;
  align-items: center;
  width: 320px;
  height: 320px;
  margin: auto;
}

.arrowSlide {
  position: absolute;
  display: flex;
  z-index: 1;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 8px;
}
.arrowSlide.disabled path{
  fill: #D1D1D1;
}
.arrowSlide:first-of-type {
  left: 4px;
  transform: rotate(180deg);
}
.arrowSlide:last-of-type {
  right: 4px;
}
