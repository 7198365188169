.spinButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
  width: 100%;
  height: 24px;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 192, 147, 1);
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  color: initial;
}

.spinButton button {
  display: grid;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
}
.spinButton button svg {
  width: 12.2px;
  height: 12.2px;
}
.spinButton button svg path {
  fill: #FE6D04;
}
