.productView {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.productView__image_plug {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 186px;
  background-color: #F2F2F2;
  margin: 24px 0;
}
.productView__image_plug svg {
  width: 66px;
  height: 56px;
  object-fit: contain;
  pointer-events: none;
}
.productView__image_plug svg path {
  fill: #BDBDBD;
}
.productView__main {
  padding: 0 16px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}
.productView__brief {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
}
.productView__price {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
}
.productView__sku {
  font-weight: 400;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: rgba(130, 130, 130, 1);
}

.productView__name {
  margin-top: 25px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;

}
.productView__about {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: left;
  font-weight: 400;
  letter-spacing: -0.4px;
  overflow-wrap: break-word;
  white-space: break-spaces;
  color: #011627;
}
.productView__footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.productView__footer .spinButton {
  width: 100%;
  height: 48px;
}
.productView__footer .spinButton button {
  width: auto;
  height: 42px;
  margin: 8px;
}
