.cartProductCard {
  display: grid;
  grid-template-columns: 100px 1fr;
  margin: 0 16px;
  padding: 15px 16px;
  border-bottom: 1px solid #F2F2F2;
}

.cartProductCard img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 6px;
}

.cartProductCard__about{
  display: flex;
  width: 100%;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
}

.cartProductCard__info{
 width: 100%;
  margin-left: 8%;
}

.cartProductCard__namePrice{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cartProductCard__countBasket{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cartProductCard__countBasket svg{
  cursor: pointer;
  width: 15px;
  height: 16.5px;
}

.cartProductCard__image_plug {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  background-color: #F2F2F2;
}
.cartProductCard__image_plug svg {
  width: 33px;
  height: 28px;
  object-fit: contain;
  pointer-events: none;
}
.cartProductCard__image_plug svg path {
  fill: #BDBDBD;
}

.cartProductCard__about .spinButton{
  width: 80px;
}

.cartProductCard__price {
  display: grid;
  align-items: center;
  height: 100%;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  white-space: nowrap;
}

.cartProductCard__name {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;

  width: 106px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  text-align: left;

  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
}