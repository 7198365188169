.shopButton {
  cursor: pointer;
  background: #FE6D04;
  border: none;
  color: #FFFFFF;
  width: 100%;
  height: 48px;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);

}
.shopButton:hover {
  transition-duration: 301ms;
  background: #FA822C;
}
.shopButton:active {
  color: #FFF4EC;
  background: #FA822C;
  /* box-shadow: none;
  transition-duration: 301ms;
  transform: translate(0em, 0.15em); */
}
.shopButton svg {
  width: 19px;
  height: 19px;
  margin-right: 16px;
}
.shopButton svg path {
  fill: white;
}