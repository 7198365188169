.shopProductCard {
    display: grid;
    grid-template-rows: 1fr 24px;
    height: 280px;
    width: 131px;
    background-color: white;
    box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.45);
    border-radius: 12px;
    padding: 16px;
}

.shopProductCard a {
    text-decoration: none;
}
.shopProductCard__content{
    display: grid;
    align-content: start;
}

.shopProductCard__image {
    width: 131px;
    height: 174px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.01em;
}

.shopProductCard__image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.shopProductCard__image_plug {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 174px;
    background-color: #F2F2F2;
    border-radius: 6px;
}

.shopProductCard__image_plug svg {
    width: 33px;
    height: 28px;
    object-fit: contain;
    pointer-events: none;
}

.shopProductCard__image_plug svg path {
    fill: #BDBDBD;
}

.shopProductCard__button {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.shopProductCard__button .shopButton {
    width: 131px;
    height: 24px;
    padding: 3px 8px 5px 8px;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;

}

.shopProductCard__button .shopButton svg {
    width: 14.53px;
    height: 14.53px;
}

.shopProductCard__button .shopButton path {
    fill: white;
}

.shopProductCard__price {
    margin-top: 10px;
    font-weight: 700;
    line-height: 18px;
    color: initial;
    width: 131px;
    height: 18px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 1;
    -moz-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shopProductCard__name {
    margin-top: 10px;
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    height: 32px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: initial;
}